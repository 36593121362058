import {
	Message
} from 'element-ui'
import {
	handleJoinTeamSave,
	handleViewDetails,
	handlePageQuery,
	handleStartStopTeam
} from '@index/api/team/teaminfo';

export default {
	name: 'teamteaminfo',
	components: {

	},
	data() {
		return {
			pageSize: 30,
			currPage: 1,
			totalCount: 0,
			teamDialogVisible: false,
			form: {},
			tableData: [],
			formRules: {
				name: [{
					required: true,
					message: '请输入比代表队名称!',
					trigger: 'blur'
				}],
				leader: [{
					required: true,
					message: '请输入领队姓名!',
					trigger: 'blur'
				}],
				coach: [{
					required: true,
					message: '请输入比教练姓名!',
					trigger: 'blur'
				}],
				contactPhone: [{
					required: true,
					message: '请输入联系方式!',
					trigger: 'blur'
				}],
			},
			dialogTitle: '新增代表队',
		};
	},
	watch: {

	},
	created() {
		this.pageQuery();
	},
	mounted() {

	},
	destroyed() {

	},
	methods: {
		handleSizeChange(pageSize) { //改变页面大小
			this.pageSize = pageSize;
			this.pageQuery();
		},
		handleCurrentChange(currPage) { //跳转到某一页
			this.currPage = currPage;
			this.pageQuery();
		},

		pageQuery() { //分页查询
			handlePageQuery({
				pageSize: this.pageSize,
				currPage: this.currPage,
				con: "",
				sortWay: [{
					fieldName: 'createDate',
					sortWay: 'DESC'
				}]
			}).then(res => {
				if (res.status == 200) {
					this.tableData = res.data;
					this.currPage = res.currPage;
					this.pageSize = res.pageSize;
					this.totalCount = res.totalCount;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5 * 1000
					})
				}
			});
		},
		joinTeamSave() { //保存代表队信息
			this.$refs.form.validate((valid) => {
				if (valid) {
					handleJoinTeamSave(this.form).then(res => {
						if (res.status == 200) {
							Message({
								message: res.msg,
								type: 'success',
								duration: 5 * 1000
							})
							this.teamDialogVisible = false;
							this.currPage = 1;
							this.pageQuery();
						} else {
							Message({
								message: res.msg,
								type: 'error',
								duration: 5 * 1000
							})
						}
					});

				} else {
					console.log('error submit!!');
					return false;
				}
			});

		},
		modify(id) { //详情
			this.dialogTitle = '修改代表队';
			this.teamDialogVisible = true;
			handleViewDetails(id).then(res => {
				if (res.status == 200) {
					this.form = res.data;
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5000
					});
				}
			});
		},
		startStopTeam(id) { //启用停用代表队
			handleStartStopTeam(id).then(res => {
				if (res.status == 200) {
					Message({
						message: res.msg,
						type: 'success',
						duration: 5000
					});
					this.pageQuery();
				} else {
					Message({
						message: res.msg,
						type: 'error',
						duration: 5000
					});
				}

			});
		},
		openAddTeamDialog() {
			this.dialogTitle = '新增代表队';
			this.form = {};
			this.teamDialogVisible = true;
		},
		toHelp() {
			window.open('help.html')
		},



	}
}